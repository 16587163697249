import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import H1Category from "../../components/h1Category"
import ContentPage from "../../layouts/contentPage"
import ProductBox from "../../components/productBox"
import ProductBoxCta from "../../components/productBox/productBoxCta"
import ProducstList from "../../components/productsList"
import Seo from "../../components/seo"

import ArrowRight from "../../images/arrow-right.svg"
import ProductBanner from "../../components/productBanner"
import Container from "../../components/Product/container"




const Category = () => (
    <Layout>
      <Seo title="Linia Energyze" />
      <ContentPage>
            <H1Category color="lime">Linia Energyze</H1Category>
            <ProducstList>

                <ProductBox data-color="lime">
                    <Link to="/produkty/energetyzujace-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/energetyzujace-serum.png"
                        loading="eager"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Energetyzujące serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Energetyzujące serum</h3>
                                <p>do twarzy na dzień SPF 15</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/produkty/dotleniajacy-sorbet">
                        <StaticImage
                        src="../../images/media/products/energyze/category/nawilzajacy-sorbet.png"
                        loading="eager"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Nawilżająco-dotleniający sorbet"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Nawilżająco-dotleniający sorbet</h3>
                                <p>do mycia twarzy</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/produkty/regeneracyjne-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/regeneracyjne-serum.png"
                        loading="eager"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Regeneracyjne serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Regeneracyjne serum</h3>
                                <p>do twarzy na noc</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/produkty/normalizujace-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/nawilzajace-serum.png"
                        loading="lazy"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Normalizujące serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Normalizujące serum <br/>naprawcze</h3>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/produkty/rewitalizujace-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/rewitalizujace-serum.png"
                        loading="lazy"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Rewitalizujące serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Rewitalizujące serum</h3>
                                <p>pod oczy</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBanner productLine="energyze"/>


          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Category
